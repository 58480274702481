.kick-off-acca-journey-bg-container {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
}
.kick-off-acca-journey-container {
  width: 75%;
  background-color: #ecf3f9;
  padding: 35px;
  display: flex;
  flex-direction: row;
}
.kick-off-acca-journey-container-heading {
  font-size: 22px;
  font-weight: 700;
}
.kick-off-acca-journey-container-description {
  font-size: 16px;
}
.start-journey-btn {
  background-color: #e80000;
  color: #ffffff;
  border: none;
  height: 42px;
  width: 200px;
  border-radius: 8px;
}
.apply-card-container {
  border-top: solid 3px #4120a9;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 20px;
}
.apply-card-heading {
  font-size: 18px;
  font-family: 'Roboto';
}
.request-call-btn {
  margin-top: 15px;
}
