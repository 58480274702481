.why-choose-us-heading {
  font-size: 20px;
  text-align: center;
  margin-bottom: 25px;
}
.why-choose-us-container {
  background-color: #ffffff;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.why-choose-us-card-image {
  width: 100px;
  height: 100px;
}
.cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90vw;
}
.card-container {
  background-color: #ecf3f9;
  padding: 35px;
  border-radius: 3px;
  margin-right: 20px;
  width: 32%;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 10px 10px 8px #888888;
}
@media screen and (max-width: 768px) {
  .card-container {
    width: 100%;
  }
  .cards-container {
    flex-wrap: wrap;
  }
}
.why-choose-us-card-heading {
  font-size: 20px;
  color: #4120a9;
  font-weight: 400;
}
.card-description {
  font-size: 16px;
}
.course-details-cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 65px;
  padding-bottom: 35px;
  width: 90vw;
  flex-wrap: wrap;
}
.course-details-card {
  border-top: solid 3px #4120a9;
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  margin-right: 20px;
  width: 20%;
  margin-bottom: 20px;
  box-shadow: 8px 9px 9px 9px #f5f5f5;
}
@media screen and (max-width: 768px) {
  .course-details-card {
    min-width: 150px;
  }
}
.course-details-card-heading {
  font-size: 20px;
  font-weight: 500;
}
.course-details-card-description {
  font-size: 16px;
}
